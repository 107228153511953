<template>
  <div class="mt-2 py-4">
    <span class="font-weight-black text-h6 mt-10 ml-4">
      REKAPITULASI LAYANAN
    </span>
    <v-data-table
      :headers="headers"
      :items="ftRegistrasisFiltered"
      :items-per-page="10"
      class="elevation-0"
    >
      <template v-slot:[`item.ftemplateRegBean`]="{ item }">
        <div class="font-weight-bold">
          {{ lookupItemTemplateReg(item.ftemplateRegBean).description }}
        </div>
      </template>

      <template v-slot:[`item.draft`]="{item}">
        <v-btn plain fab dark text color="primary" class="font-weight-black"
          >{{item.draft}}</v-btn
        >
      </template>
      <template v-slot:[`item.menungguVerifikasi`]="{item}">
        <v-btn
          plain
          fab
          dark
          text
          color="yellow darken-4"
          class="font-weight-black"
          >{{ item.menungguVerifikasi }}</v-btn
        >
      </template>
      <template v-slot:[`item.prosesVerifikasi`]="{item}">
        <v-btn
          plain
          fab
          dark
          text
          color="teal"
          class="font-weight-black"
          >{{ item.prosesVerifikasi }}</v-btn
        >
      </template>
      <template v-slot:[`item.ditolakBts`]="{item}">
        <v-btn
            plain
            fab
            dark
            text
            color="red"
            class="font-weight-black pa-0 ma-0"
        >
          {{item.ditolakBts}}
        </v-btn>
      </template>
      <template v-slot:[`item.ditolakTms`]="{item}">
        <v-btn plain fab dark text color="red" class="font-weight-black">{{item.ditolakTms}}</v-btn>
      </template>
      <template v-slot:[`item.approved`]="{item}">
        <v-btn plain fab text color="success" class="font-weight-black"
          >{{item.approved}}</v-btn
        >
      </template>
      <template v-slot:[`item.totalPengajuan`]="{ item }">
        <v-btn plain fab text class="font-weight-black">{{
          item.totalPengajuan
        }}</v-btn>
      </template>
    </v-data-table>

  </div>
</template>
  
  <script>
import FtRegistrasiService from "@/services/apiservices/ft-registrasi-service";
import FtRegistrasi from "@/models/ft-registrasi";
import FTemplateRegService from "@/services/apiservices/f-template-reg-service";
export default {
  name: "InfografikBasic",
  data() {
    return {
      headers: [
        {
          text: "Jenis Layanan",
          align: "start",
          sortable: false,
          value: "ftemplateRegBean",
        },
        {
          text: "Draft",
          align: "center",
          sortable: false,
          value: "draft",
        },
        {
          text: "Menunggu Verifikasi",
          align: "center",
          sortable: false,
          value: "menungguVerifikasi",
        },
        {
          text: "Proses Verifikasi",
          align: "center",
          sortable: false,
          value: "prosesVerifikasi",
        },
        {
          text: "Berkas Tidak Sesuai",
          align: "center",
          sortable: false,
          value: "ditolakBts",
        },
        {
          text: "Berkas Tidak Memenuhi Syarat",
          align: "center",
          sortable: false,
          value: "ditolakTms",
        },
        {
          text: "Terverifikasi",
          align: "center",
          sortable: false,
          value: "approved",
        },
        {
          text: "Total Pengajuan",
          align: "center",
          sortable: false,
          value: "totalPengajuan",
        },
      ],
      ftRegistrasis: [new FtRegistrasi()],
      itemsFTemplateReg: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    ftRegistrasisFiltered() {
      let filtered = this.ftRegistrasis;

      // Calculate the values for each condition
      const mapping = filtered.reduce((acc, item) => {
        const {
          ftemplateRegBean,
          submitted,
          verifikatorBean,
          validasiStatus,
          validasiReasonCode
        } = item;

        // Skip if ftemplateRegBean is 0
        if (ftemplateRegBean === 0) return acc;

        // Determine the status for each column
        let draftStatus = (submitted === false && validasiStatus === 0 && validasiReasonCode === 0) ? 1 : 0;
        let menungguVerifikasiStatus = (submitted === true && verifikatorBean === 0 && validasiStatus === 0 && validasiReasonCode !== 2) ? 1 : 0;
        let prosesVerifikasiStatus = (submitted === true && verifikatorBean > 0 && validasiStatus === 0 && validasiReasonCode === 0) ? 1 : 0;
        let ditoalkBtsStatus = (submitted === false  && validasiReasonCode === 1) ? 1 : 0;
        let ditolakTmsStatus = (submitted === true  && validasiReasonCode === 2) ? 1 : 0;
        let approvedStatus = validasiStatus === 1 ? 1 : 0;
        let totalPengajuanStatus = submitted === true ? 1 : 0;

        // If the current item has a ftemplateRegBean, map it accordingly
        if (!acc[ftemplateRegBean]) {
          acc[ftemplateRegBean] = {
            draft: 0,
            menungguVerifikasi: 0,
            prosesVerifikasi: 0,
            ditolakBts: 0,
            ditolakTms: 0,
            approved: 0,
            totalPengajuan: 0
          };
        }

        acc[ftemplateRegBean].draft += draftStatus;
        acc[ftemplateRegBean].menungguVerifikasi += menungguVerifikasiStatus;
        acc[ftemplateRegBean].prosesVerifikasi += prosesVerifikasiStatus;
        acc[ftemplateRegBean].ditolakBts += ditoalkBtsStatus;
        acc[ftemplateRegBean].ditolakTms += ditolakTmsStatus;
        acc[ftemplateRegBean].approved += approvedStatus;
        acc[ftemplateRegBean].totalPengajuan += totalPengajuanStatus;

        return acc;
      }, {});

      // Return the mapped result as an array
      return Object.keys(mapping).map((ftemplateRegBean) => ({
        ftemplateRegBean: parseInt(ftemplateRegBean), // Keep the ftemplateRegBean value as it is
        ...mapping[ftemplateRegBean],      // Spread the computed counts into the returned object
      }));
    },
  },
  methods: {
    fetchFtRegistrasi() {
      if(this.currentUser.organizationLevel === 'DIV'){
        FtRegistrasiService.getAllFtRegistrasiByDivision(this.currentUser.fdivisionBean).then(
            (response) => {
              this.ftRegistrasis = response.data;
            },
            (err) => {
              console.log(err);
            }
        );
      } else {
        FtRegistrasiService.getAllFtRegistrasi().then(
            (response) => {
              this.ftRegistrasis = response.data;
            },
            (err) => {
              console.log(err);
            }
        );
      }
    },
    fetchParent() {
      FTemplateRegService.getAllFTemplateReg().then(
        (response) => {
          this.itemsFTemplateReg = response.data;
        },
        (err) => {
          console.log(err);
        }
      );
    },
    lookupItemTemplateReg(fTemplateRegBean) {
      const str = this.itemsFTemplateReg.filter(
        (x) => x.id === fTemplateRegBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
  },
  mounted() {
    this.fetchFtRegistrasi();
    this.fetchParent();
  },
};
</script>
  
  <style scoped>
</style>