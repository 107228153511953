<template>
  <nav>
    <v-toolbar :flat="currentUser === null" elevation="0">
      <v-app-bar-nav-icon
          @click="drawer = !drawer"
          class="grey--text"
          v-if="currentUser"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-bold orange--text">SI</span>
        <span class="pink--text font-weight-bold">LAYAK</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span v-if="false" class="mr-10 subtitle-1 red--text text--darken-2">SISTEM DALAM MODE LATIHAN (SEMUA INPUTAN TANGGAL 4 FEB 2025 TIDAK AKAN DISIMPAN)</span>
      <v-spacer v-if="false"></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-menu offset-y v-if="currentUser">
          <template v-slot:activator="{ on, attrs }">
            <v-btn plain v-bind="attrs" v-on="on">
              <span>{{ currentUser.username }} </span>
              <v-avatar color="grey lighten-1" size="36">
                <v-img
                    :lazy-src="lookupImageUrlLazy(currentUser.avatarImage)"
                    :src="lookupImageUrl(currentUser)"
                ></v-img>
              </v-avatar>
            </v-btn>
          </template>
          <v-list color="grey lighten-4">
            <v-list-item
                v-for="link in top_menus"
                :key="link.title"
                route
                :to="link.route"
                @click="handleClick(link.action)"
            >
              <v-list-item-icon>
                <v-icon small :color="link.iconColor">{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{link.title}}
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-menu>
      </v-toolbar-items>

      <v-toolbar-items class="mx-0">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="false"
                dark
                v-bind="attrs"
                v-on="on"
                rounded
                icon
                class="px-5"
                x-small
            >
              <v-icon color="black">mdi-translate</v-icon>
              <v-avatar size="18" class="elevation-1">
                <v-img :src="getFlagImage(lang)"></v-img>
              </v-avatar>
            </v-btn>
          </template>

          <v-list v-if="false">
            <v-list-item
                v-for="(item, index) in menu_language"
                :key="index"
                dense
            >
              <v-list-item-title>
                <v-avatar v-if="false" size="22" class="elevation-1">
                  <v-img :src="getFlagImage(item.id)"></v-img>
                </v-avatar>
                <v-btn
                    small
                    plain
                    elevation="0"
                    @click="languageChange(item.id)"
                >
                  {{ item.description }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>

    <v-navigation-drawer
      app
      class="color-gradient-2"
      v-model="drawer"
      v-if="showNavbar"
    >
      <v-layout column class="align-center">
        <v-flex class="mt-5" v-if="true">
          <v-img
            lazy-src="../assets/logo.png"
            max-height="150"
            max-width="150"
            contain
            src="../assets/logo.png"
          ></v-img>
        </v-flex>

        <v-flex class="mt-n5">
          <div class="title text-center">
            <h2>
              <div class="waviy mb-4">
                <span style="--i: 1">S</span>
                <span style="--i: 2">I</span>
                <span style="--i: 1">L</span>
                <span style="--i: 1">A</span>
                <span style="--i: 2">Y</span>
                <span style="--i: 1">A</span>
                <span style="--i: 2">K</span>
              </div>
            </h2>
            <v-flex class="mt-n6">
              <div class="my-title1">BKPSDM</div>
            </v-flex>
            <v-flex class="mt-n4">
              <div class="my-title2">Kota Palangkaraya</div>
            </v-flex>
          </div>
        </v-flex>
      </v-layout>

      <v-divider></v-divider>

      <v-list>
        <v-list-item route to="/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-for="nav_drawer in nav_drawers_transaksi.filter((x) => x.visible)"
          :key="nav_drawer.title"
          :prepend-icon="nav_drawer.icon"
          no-action
          small
          v-model="nav_drawer.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="body-2" v-text="nav_drawer.title">
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="submenu in nav_drawer.items.filter((x) => x.visible)"
            :key="submenu.title"
            link
            router
            :to="submenu.route"
            class="navbar_submenu_color"
          >
            <template>
              <v-list-item-content>
                <v-list-item-title class="body-2" v-text="submenu.title">
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-icon>
              <v-icon small>{{ submenu.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-list-group
          v-for="nav_drawer in nav_drawers_setup.filter((x) => x.visible)"
          :key="nav_drawer.title"
          :prepend-icon="nav_drawer.icon"
          no-action
          small
          v-model="nav_drawer.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="body-2" v-text="nav_drawer.title">
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="submenu in nav_drawer.items.filter((x) => x.visible)"
            :key="submenu.title"
            link
            router
            :to="submenu.route"
            class="navbar_submenu_color"
          >
            <template>
              <v-list-item-content>
                <v-list-item-title class="body-2" v-text="submenu.title">
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-icon>
              <v-icon small>{{ submenu.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import ERole from "@/models/e-role";
import FileService from "@/services/apiservices/file-service";
import TestService from "../services/test-service";

export default {
  components: {},
  data() {
    return {
      top_menus: [
        {
          icon: "mdi-help-box",
          title: "Ubah Profile",
          route: "/change-profile",
          visible: true,
        },
        {
          icon: "mdi-power",
          title: "Logout",
          route: "#",
          action: "logOut",
          visible: true,
          iconColor: "red",
        },

      ],

      drawer: true,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showNavbar() {
      if (this.currentUser && this.currentUser.roles) {
        return true;
      }
      return false;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes(ERole.ROLE_ADMIN);
      }
      return false;
    },

    nav_drawers_transaksi() {
      return [
        {
          title: "Layanan",
          icon: "mdi-file-document",
          active: false,
          visible: [
            ERole.ROLE_ADMIN,
            ERole.ROLE_USER,
            ERole.ROLE_VERIFIKATOR,
          ].some((x) => this.currentUser.roles.includes(x)),
          items: [
            {
              title: "Usulan Layanan",
              route: "/registrasi-layanan",
              icon: "",
              visible: [
                ERole.ROLE_ADMIN,
                ERole.ROLE_USER,
                ERole.ROLE_VERIFIKATOR,
              ].some((x) => this.currentUser.roles.includes(x)),
            },
          ],
        },
        {
          title: "Verifikasi",
          icon: "mdi-format-list-checks",
          active: false,
          visible: [ERole.ROLE_ADMIN, ERole.ROLE_VERIFIKATOR].some((x) =>
            this.currentUser.roles.includes(x)
          ),
          items: [
            {
              title: "Verifikasi Usulan",
              route: "/validasi-validasi",
              icon: "",
              visible: [ERole.ROLE_ADMIN, ERole.ROLE_VERIFIKATOR].some((x) =>
                this.currentUser.roles.includes(x)
              ),
            },
          ],
        },

        {
          title: "Approval",
          icon: "mdi-star",
          active: false,
          visible: [ERole.ROLE_ADMIN, ERole.ROLE_APPROVAL].some((x) =>
            this.currentUser.roles.includes(x)
          ),
          items: [
            {
              title: "Approval",
              route: "/approval-approval",
              icon: "mdi-star",
              visible: [ERole.ROLE_ADMIN, ERole.ROLE_APPROVAL].some((x) =>
                this.currentUser.roles.includes(x)
              ),
            },
          ],
        },
        {
          title: "Infografik",
          icon: "mdi-chart-pie",
          active: false,
          visible: [
            ERole.ROLE_ADMIN,
            ERole.ROLE_USER,
            ERole.ROLE_VERIFIKATOR,
            ERole.ROLE_APPROVAL,
            ERole.ROLE_PUBLIC,
          ].some((x) => this.currentUser.roles.includes(x)),
          items: [
            {
              title: "Infografik",
              route: "/infografik-infografik-basic",
              icon: "mdi-chart-pie",
              visible: true,
            },
            {
              title: "Laporan",
              route: "/infografik-laporan",
              icon: "mdi-file",
              visible: [ERole.ROLE_ADMIN, ERole.ROLE_VERIFIKATOR].some((x) =>
                this.currentUser.roles.includes(x)
              ),
            },
          ],
        },
        {
          title: "Setup Layanan",
          icon: "mdi-cog",
          active: false,
          visible: [ERole.ROLE_ADMIN].some((x) =>
              this.currentUser.roles.includes(x)
          ),
          items: [
            {
              title: "*Template Layanan",
              route: "/setup-template",
              icon: "mdi-list",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "**Jenis Dokumen",
              route: "/setup-jenis-dokumen",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "**Template Group",
              route: "/setup-template-reg-group",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "-",
              route: "#",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },

            {
              title: "Prosedur",
              route: "/setup-prosedur",
              icon: "mdi-bridge",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Kenaikan Pangkat",
              route: "/setup-kenaikan-pangkat",
              icon: "mdi-ruler",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Jabatan",
              route: "/setup-jabatan",
              icon: "mdi-ruler",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Jenis Kartu",
              route: "/setup-kartu",
              icon: "mdi-card-account-details-outline",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
          ],
        },

        {
          title: "Setup Aplikasi",
          icon: "mdi-folder-multiple-image",
          active: false,
          visible: [ERole.ROLE_ADMIN].some((x) =>
              this.currentUser.roles.includes(x)
          ),
          items: [
            {
              title: "Setting Aplikasi",
              route: "/admin-setting-one",
              icon: "mdi-cogs",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x) && this.currentUser.organizationLevel !== "DIV"
              ),
            },
            {
              title: "Carousel Background",
              route: "/admin-din-carousel",
              icon: "mdi-list",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                  this.currentUser.roles.includes(x)
              ),
            },
          ]
        }
      ];
    },
    nav_drawers_setup() {
      return [

        {
          title: "Admin",
          icon: "mdi-cog-outline",
          active: false,
          visible: [ERole.ROLE_ADMIN].some((x) =>
            this.currentUser.roles.includes(x)
          ),
          items: [
            {
              title: "Pengguna",
              route: "/admin-sistem",
              icon: "",
              visible: [ERole.ROLE_ADMIN].some((x) =>
                this.currentUser.roles.includes(x)
              ),
            },
            {
              title: "Dinas OPD",
              route: "/admin-struktur-organisasi",
              icon: "",
              visible: [ERole.ROLE_ADMIN, ERole.ROLE_USER].some((x) =>
                this.currentUser.roles.includes(x) && this.currentUser.organizationLevel !== "DIV"
              ),
            },
          ],
        },
      ];
    },
  },
  methods: {
    handleClick(action) {
      if (action !== "") {
        this[action]();
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    lookupImageUrl(item) {
      // console.log(JSON.stringify(item))

      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/account_icon.png";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
    lookupImageUrlLazy() {
      return "./assets/images/account_icon.png";
    },
  },

  mounted() {
    TestService.getAdminBoard().then(
      () => {},
      (error) => {
        console.log(`${error.response.status}`);
        if (error.response.status === 401) {
          if (this.currentUser !== undefined || this.currentUser !== "") {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
          }
        }
      }
    );
  },
};
</script>


<style scoped>
.my-title1 {
  font-size: 20px;
  font-weight: bolder;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(to right, #3f51b5, #2196f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 8s ease infinite;

  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #4ac050 67%,
    #fff800 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip1 1.5s linear infinite;
  display: inline-block;
  font-size: 20px;
}
@keyframes textclip1 {
  to {
    background-position: 300% center;
  }
}

.my-title2 {
  font-size: 14px;
  font-weight: bolder;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(to right, #3f51b5, #2196f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease infinite;

  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #4ac050 67%,
    #fff800 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip2 2s linear infinite;
  display: inline-block;
  font-size: 14px;
}
@keyframes textclip2 {
  to {
    background-position: 200% center;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.waviy {
  position: relative;
  font-weight: bolder;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 30px;
  color: #0f2270;
  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(0.2s * var(--i));
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}
</style>