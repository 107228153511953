import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/public/Home.vue";
import store from "../store/index";
import Dashboard from "../views/DashboardView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },

    //ACCOUNT REGISTER
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/registration/RegistrationView.vue"),
  },
  {
    path: "/register-luar",
    name: "RegisterLuar",
    component: () => import("../views/registration/RegistrationLuarView.vue"),
  },
  {
    path: "/registration-masukkan-otp",
    name: "RegistrationMasukkanOtp",
    component: () => import("../views/registration/RegistrationMasukanOtpView.vue"),
  },
  {
    path: "/registation-active",
    name: "RegistrationActive",
    component: () => import("../views/registration/RegistrationActiveView.vue"),
  },
  {
    path: "/select-account-type",
    name: "SelectAccountType",
    component: () => import("../views/registration/SelectAccountTypeView.vue"),
  },
  {
    path: "/reset-atur-ulang",
    name: "ResetAturUlang",
    component: () => import("../views/reset-password/ResetAturUlangView.vue"),
  },
  {
    path: "/reset-masukkan-otp-password",
    name: "ResetMasukanOtpPaswordView",
    component: () => import("../views/reset-password/ResetMasukanOtpPasswordView.vue"),
  },
  {
    path: "/reset-success",
    name: "ResetSuccess",
    component: () => import("../views/reset-password/ResetSuccessView.vue"),
  },

  {
    path: "/reset-metode-verifikasi",
    name: "ResetMetodeVerifikasiView",
    component: () => import("../views/reset-password/ResetMetodeVerifikasiView.vue"),
  },
  // {
  //   path: "/reset-masukkan-otp",
  //   name: "ResetMasukanOtp",
  //   component: () => import("../views/reset-password/ResetMasukanOtpView.vue"),
  // },
  {
    path: "/reset-new-password",
    name: "ResetNewPassword",
    component: () => import("../views/reset-password/ResetNewPasswordView.vue"),
  },
  {
    path: "/change-profile",
    name: "ChangeProfile",
    component: () => import("../views/admin/ChangeProfile"),
  },
  {
    path: "/admin-sistem",
    name: "Setting",
    component: () => import("../views/admin/SettingAndUser.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/admin-struktur-organisasi",
    name: "StrukturOrganisasiView",
    component: () => import("../views/admin/StrukturOrganisasi.vue"),
  },
  {
    path: "/dashboard",
    name: "DashboardView",
    component: Dashboard,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },

  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: () => import("../views/BoardAdmin.vue"),
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: () => import("../views/BoardUser.vue"),
  },
  {
    path: "/admin-setting-one",
    name: "AdminSettingOne",
    component: () => import("../views/admin/SettingOneView.vue"),
  },
  {
    path: "/admin-din-carousel",
    name: "DinCarouselView",
    component: () => import("../views/admin_setup/DinCarouselView.vue"),
  },

  //REGISTRASI
  {
    path: "/registrasi-layanan",
    name: "RegistrasiLayananView",
    component: () =>
      import("../views/admin_registrasi/RegistrasiLayananView.vue"),
  },
  {
    path: "/registrasi-layanan/:id",
    name: "RegistrasiLayananTableView",
    component: () =>
      import("../views/admin_registrasi/RegistrasiLayananTableView.vue"),
  },
  {
    path: "/registrasi-layanan-group/:id",
    name: "RegistrasiLayananGrupTableView",
    component: () =>
        import("../views/admin_registrasi/RegistrasiLayananGroupTableView.vue"),
  },

  {
    path: "/registrasi-layanan-edit/:id",
    name: "RegistrasiLayananEditView",
    component: () =>
      import("../views/admin_registrasi/RegistrasiLayananEditView.vue"),
  },

  {
    path: "/verifikasi-login-link/:time_milis/:id",
    name: "VerifikasiLoginLinkView",
    component: () => import("../views/public/VerifikasiLoginLinkView.vue"),
  },
  {
    path: "/verifikasi-login-link-send",
    name: "VerifikasiLoginLinkSendView",
    component: () => import("../views/public/VerifikasiLoginLinkSendView.vue"),
  },

  {
    path: "/validasi-validasi",
    name: "ValidasiView",
    // lazy-loaded
    component: () => import("../views/admin_validasi/ValidasiView"),
  },
  {
    path: "/validasi-verifikasi/:id",
    name: "ValidasiVerifikasiView",
    // lazy-loaded
    component: () => import("../views/admin_validasi/ValidasiVerifikasiDocView.vue"),
  },

  {
    path: "/approval-approval",
    name: "ApprovalView",
    // lazy-loaded
    component: () => import("../views/admin_approval/ApprovalView"),
  },
  {
    path: "/infografik-infografik-basic",
    name: "InfografikBasicView",
    // lazy-loaded
    component: () => import("../views/admin_infografik/InfografikBasicView"),
  },
  {
    path: "/infografik-laporan",
    name: "LaporanView",
    // lazy-loaded
    component: () => import("../views/admin_infografik/LaporanView.vue"),
  },

  {
    path: "/setup-prosedur",
    name: "SetupProsedur",
    // lazy-loaded
    component: () => import("../views/admin_setup/JenisProsedurView"),
  },
  {
    path: "/setup-jabatan",
    name: "SetupJabatan",
    // lazy-loaded
    component: () => import("../views/admin_setup/JenisJabatanView"),
  },
  {
    path: "/setup-kartu",
    name: "SetupKartu",
    // lazy-loaded
    component: () => import("../views/admin_setup/JenisKartuView"),
  },
  {
    path: "/setup-kenaikan-pangkat",
    name: "SetupKenaikanPangkat",
    // lazy-loaded
    component: () => import("../views/admin_setup/JenisKenaikanPangkatView"),
  },
  {
    path: "/setup-jenis-dokumen",
    name: "SetupJenisDokumenView",
    // lazy-loaded
    component: () => import("../views/admin_setup/JenisDokumenView"),
  },
  {
    path: "/setup-template-reg-group",
    name: "SetupTemplateRegView",
    // lazy-loaded
    component: () => import("../views/admin_setup/TemplateRegGroupView.vue"),
  },
  {
    path: "/setup-template",
    name: "SetupTemplateReg",
    // lazy-loaded
    component: () => import("../views/admin_setup/TemplateRegView"),
  },
  {
    path: "/chart-visitor",
    name: "ChartVisitorView",
    component: () => import("../views/public/ChartVisitorView"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let usingAuthLinks = ["Profile"];
router.beforeEach((to, from, next) => {
  if (store.state.auth.user === null) {
    if (usingAuthLinks.includes(to.name)) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
