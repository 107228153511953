var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2 py-4"},[_c('span',{staticClass:"font-weight-black text-h6 mt-10 ml-4"},[_vm._v(" REKAPITULASI LAYANAN ")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.ftRegistrasisFiltered,"items-per-page":10},scopedSlots:_vm._u([{key:"item.ftemplateRegBean",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.lookupItemTemplateReg(item.ftemplateRegBean).description)+" ")])]}},{key:"item.draft",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-black",attrs:{"plain":"","fab":"","dark":"","text":"","color":"primary"}},[_vm._v(_vm._s(item.draft))])]}},{key:"item.menungguVerifikasi",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-black",attrs:{"plain":"","fab":"","dark":"","text":"","color":"yellow darken-4"}},[_vm._v(_vm._s(item.menungguVerifikasi))])]}},{key:"item.prosesVerifikasi",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-black",attrs:{"plain":"","fab":"","dark":"","text":"","color":"teal"}},[_vm._v(_vm._s(item.prosesVerifikasi))])]}},{key:"item.ditolakBts",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-black pa-0 ma-0",attrs:{"plain":"","fab":"","dark":"","text":"","color":"red"}},[_vm._v(" "+_vm._s(item.ditolakBts)+" ")])]}},{key:"item.ditolakTms",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-black",attrs:{"plain":"","fab":"","dark":"","text":"","color":"red"}},[_vm._v(_vm._s(item.ditolakTms))])]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-black",attrs:{"plain":"","fab":"","text":"","color":"success"}},[_vm._v(_vm._s(item.approved))])]}},{key:"item.totalPengajuan",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-black",attrs:{"plain":"","fab":"","text":""}},[_vm._v(_vm._s(item.totalPengajuan))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }